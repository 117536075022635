import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import PageMap from './routes/PageMap';
import PagePortal from './routes/PagePortal';
import PageBase from './routes/PageBase';
import PageVerify from './routes/PageVerify';

// Set root API URL
window.mainURL = '.leadar.net/';
// Set app name
window.appName = 'Property Portal';
// Set selling term
window.appSellTerm = 'Reserve';
// Set selling term
window.appSellTerm2 = 'Reservation';
// Set selling term
window.appSellTerm3 = 'Reservations';

ReactDOM.render(
    <Router>
        <Routes>
            {/* <Route path=":businessname/verify/:tl/:vh" element={<PageVerify />} />
            <Route index path=":businessname/portal/" element={<PagePortal />} /> */}
            <Route path=":businessname/map/:mapid" element={<PageMap/>} />
            <Route path="*" element={<PageBase />} />
        </Routes>
    </Router>
,document.getElementById('root')
);
