import React from 'react';
import styles from './Map.module.css';

function SinglePropHeading(props) {
  //Swap stats based on stats number
  function statusHandler(){
    if(props.status === 1){
      return 'Available';
    }else if (props.status === 2) {
      return 'Pending';
    }else if (props.status === '200') {
      return 'Selected';
    } else {
      return 'Sold';
    }
  }
  
  return (
  <div className={styles.header_con}>
    <div className={styles.title}>{props.title}</div>
    <div className={`${styles.status} ${styles[`status_`+props.status]}`} ><div>{statusHandler(props.status)}</div></div>
  </div>
  );
}


export default SinglePropHeading;