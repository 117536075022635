import React, { useState } from 'react';
import styles from '../map/Map.module.css';

function MessageAlert(props){
  const [themessage, setthemessage] = useState(props.message)

  function loadHandler() {
   document.getElementById('messagealertid').classList.add(`${styles.active}`)
  }

  function closeHandler () {
    document.getElementById('messagealertid').classList.remove(`${styles.active}`)
    setthemessage();
  }

  return(
    <>
    { themessage ?  <div onLoad={loadHandler} id='messagealertid' className={`${styles.messagealert} ${styles[`messagealert`+themessage.alertstatus]}`}><div className={styles.message_con}>{themessage.alertmessage}</div><div className={styles.close_message} onClick={closeHandler}><img src="../../close_icon.svg" /></div> </div> : ''  }
    </>
  );
}

export default MessageAlert;