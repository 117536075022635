import React from 'react';
import styles from './Map.module.css';


function SinglePropRow(props) {
  return (
    <div className={styles.detail_row}>
      <div>{props.name}</div>
      <div className={styles.number}>{props.number}</div>
    </div>
  );
}

export default SinglePropRow;