import React, { useState, useEffect } from 'react';
import styles from './Map.module.css';
import LoadingIcon from '../utilities/LoadingIcon';

  const ImageModal = (props) => {

    // Popup gallery
    // Whats happening - We pass in the props an array if images and meta. 
    // The first image [0] gets set in setlocalImage() and displays. Then setlocalArrayImages() runs over the array and passes into setlocalImage with next and prev. 
    const [localImage, setlocalImage] = useState({'index':0,'url':`${window.businessnamewindowroot.slice(0, -1)+props.imagearray[0].media_upload}`, 'title':`${props.imagearray[0].media_name}`, 'desc':`${props.imagearray[0].media_desc}`})
    const [localArrayImages, setlocalArrayImages] = useState(props.imagearray)

    console.log(props.imagearray)

    // Function updates the state with the next and prev image. If unfidned then restart loop.
    function setNextImage(way){
      var add;
      if(way==='prev'){
        add = localImage.index - 1;
      }else{
        add = localImage.index + 1;
      }
      var nextindexImage = add
      if(localArrayImages[nextindexImage] == null){

        if(way==='next'){
        nextindexImage = 0
        }else{
          nextindexImage = localArrayImages.length-1;
        }
      }
      setlocalImage({'index':nextindexImage,'url':`${window.businessnamewindowroot.slice(0, -1)+localArrayImages[nextindexImage].media_upload}`, 'title':`${localArrayImages[nextindexImage].media_name}`, 'desc':`${localArrayImages[nextindexImage].media_desc}`})
    }

    return (
    <div className={`${styles.imagepopmaincon}`}>
      <div className={`${styles.backdrop}`}>
        <div className={`${styles.image_con_main}`} style={{backgroundImage: `url(`+localImage.url+`)`}}>
        {localImage ? '' : <LoadingIcon className={`${styles.loadingIconStyleImagePop}`} size='1' />}
        <div title='Close' className={styles.close_btn2} onClick={()=>{props.imagePopHandlerClosePass()} }><div>CLOSE</div><img src='../../icon_cross_white.svg' className={styles.icon} /></div>
        {localImage.title ? <div className={`${styles.image_pop_title}`}>{localImage.title}</div>: ''}
        {localImage.desc ? <div className={`${styles.image_pop_copy}`}>{localImage.desc}</div> : ''}
        </div>
        {localArrayImages.length > 1 ? <>
        <img onClick={()=>setNextImage('next')}  className={`${styles.pop_next}`}  src='../../icon_next_1.svg' />
        <img onClick={()=>setNextImage('prev')}  className={`${styles.pop_prev}`}  src='../../icon_prev_1.svg' />
        </>
        : '' }
      </div>
    </div>
    
  );
}
export default ImageModal;