import React, {useState} from 'react';
import FormBuyer from './BuyerForm';
import Backdrop from '../utilities/Backdrop';
import CountDownTimer from './CountDownTimer';
import styles from './Map.module.css';
import PropDetailsSimple from './PropDetailsSimple';

//function BookingModal(props){
  const BookingModal = (props) => {

  console.log(props.passingpasspropertyState2)
  console.log(props.selectedunit)
  // Is booking form vis state
  const [bookVis, setbookVis] = useState(true)

  // Booking Form
  function BookingForm() {
    function handleValidation() {
      let errors = {};
      let formIsValid = true;
      return formIsValid;
    }

    return (
      <div className={`${styles.form_con}`}>
        <FormBuyer passingapiurl={props.passingapiurl} forwardcancelHandlerSetForward={props.cancelHandlerSetForward} passunit={props.selectedunit} forwardsuccess={props.forwardbookingmod} passingsetpassingMessage={props.passingsetpassingMessage} />
      </div>
    )

  }

    return (
    <>
    <Backdrop />

    <div title='Click here to cancel.' className={styles.close_btn} onClick={() => props.cancelHandlerSetForward()}>CANCEL {window.appSellTerm2} <img src='../../icon_cross_white.svg' className={styles.icon} /></div>

    <div className={`${styles.bookingmodal}`}>
      <div className={styles.counter}>{<CountDownTimer  allocatedtime={props.passingpasspropertyState2.features[0].properties.booking_time_short} forwardformvis={setbookVis} forwardcancelHandlerSetForward={props.cancelHandlerSetForward} />}</div>
      
      <div className={styles.rl_con}>

        <div className={styles.left_con}>

          <div className={styles.help_btn} onClick={() => document.getElementById('right_side_mod').classList.add(styles.show)}><img src='../../icon_arrow_left.svg' className={styles.icon} /> {window.appSellTerm2}/help details</div>

          <div className={styles.mainheading}><img src='../../icon_clock.svg' className={styles.icon} />Complete Your Property {window.appSellTerm2}</div>
          <div className={styles.into_text}>Thank you for you interest in this property.<br/> You have a few minutes to fill out your details below to retain your {window.appSellTerm2}. Keep an eye on the timer above. </div>
          { bookVis ? <BookingForm /> : <div className={`${styles.openloading}`+`${styles.error}`}><img src='./error_icon.svg' /><div>TIME OUT</div> <div>Sorry! You ran out of time. <br/><br/>Your {window.appSellTerm2} as been reopened to the public. Please try again. </div></div> }
        </div>
        <div className={styles.right_con} id='right_side_mod'>

          <div className={styles.close} onClick={() => document.getElementById('right_side_mod').classList.remove(styles.show)}><img src='../../icon_cross_grey.svg' className={styles.icon} /></div>

          <PropDetailsSimple unit={props.selectedunit} />

          <div className={styles.help_con}>

            <div className={styles.heading}>How it works</div>
            <div className={styles.steps_con}>
              <div className={`${styles.step}`+`${styles.active}`}>
                <span>1</span>
                Once you select a property. You have a few minutes to fill out your details. 
              </div>

              <div className={styles.step}>
                <span>2</span>
                Once your initial details have been submitted you will receive a confirmation email to access your admin portal (Please check your spam/junk folder). Click the verification link on the email and complete the additional steps once logged into the portal. 
              </div>

              <div className={styles.step}>
                <span>3</span>
                Once all documents have been uploaded and confirmed your booking will be secured. All relavant property applications will follow. 
              </div>
            </div>

          </div>

        </div>

    </div>

    </div>
    </>
  );
}
export default BookingModal;