import React, { useEffect, useState, Component, useRef } from 'react';

//import { MapContainer, TileLayer, Marker, Popup, ImageOverlay  } from 'react-leaflet';
import MapMain from './MapMain';
//import FormBuyer from 'BuyerForm';
import SingleProperty from './SingleProperty';
import BookingModal from './BookingModal';
import MessageAlert from '../utilities/MessageAlert';
import styles from './Map.module.css';
import PropDetailsSimple from './PropDetailsSimple';
import FormEnquiry from './FormEnquiry';
import ImageModal from './ImageModal';

function Container(props) {

  const allgroupproperties = props.passpropertyState2.features[0]

  console.log(allgroupproperties)
  const geoRef = useRef(null); // Set geoJson from map app. Did a forward. So its reachable to other apps.

  const [passingMessage, setpassingMessage] = useState()
  const [thebookingModal, setthebookingModal] = useState()
  const [buyBtnStatus, setbuyBtnStatus] = useState(true) // Used to check the status of the buy btn. For loading not loading and disbaling. Stopping multi clicks.
  const [floorState, updateFloorState] = useState(0)
  const [toggle, setToggle] = useState(false) // Floor toggle state.

  const [EnquiryViewProp, setEnquiryViewProp] = useState()
  const [EnquiryViewState, setEnquiryViewState] = useState() //  toggle state.

  const [imagepopViewState, setimagepopViewState] = useState() //  toggle state.


  const toolTipStylesHandler = (e) =>{
    console.log(e)
    return {
      fillColor: '',
      fillOpacity: '0.8',
      color: '#000000', // Stroke fill
      weight: '1', // Stroke weight
      className: `${styles.plot_class} ${styles[`plot_class_`+e.properties.listing_status]}`+` plot_`+e.id
    }
  }
  

  const imagePopHandlerClose = (e) =>{
    setimagepopViewState()
  }
  
  const toggleFloors = () =>{
    //Toggle - Toggle example if prev false then set. 
    setToggle((prev) => !prev);
  }

  const openEnqView = (unit) =>{
    setEnquiryViewState(true);
    setEnquiryViewProp(unit);
  }

  const closeEnqView = (passID) =>{
  setEnquiryViewState(false);
  setEnquiryViewProp();

  // Trigger select when close full details. Delay due to state.
  setTimeout(function() {document.getElementById('single_prop_id_'+passID).click()}, 1000);

  }

  // Create a second hidden btn sidebar_details_close_btn_hidden for just layer click. So it does not trigger anouther auto selcte like the function above. This is hidden btn. For reseting and hidding the side bar if slected from layer.
  const closeEnqViewFromLayerReset = () =>{
    setEnquiryViewState(false);
    setEnquiryViewProp();
  }
  


  // Filter out all props to see what floors we have. so we can apply to drop down.
  var allfloors = allgroupproperties.properties.group_listings.features.map(item => item.properties.property_floor_level);
  //var allfloors = propertyState.properties.map(item => item.floor); #OLD
  const floorSet = new Set(allfloors); // Remove dups
  const floorSetyArr = [...floorSet]; // Put back into an array
  const floorlabelingarray = ['Ground', 'First', 'Second', 'Third', 'Fouth', 'Fifth', 'Sixth', 'Seventh']

  function Floors(props) {

    const boxes = document.getElementsByClassName(`${styles.plot_selected_marker_little}`);

  
    for (const box of boxes) {
      console.log(box)
      // 👇️ Remove element from DOM
     // box.style.display = 'none !important';
      box.remove();
      // 👇️ hide element (still takes up space on page)
      // box.style.visibility = 'hidden';
    }

    return(
      <li onClick={() => {updateFloorState(props.floornumber); toggleFloors()}}>{floorlabelingarray[props.floornumber]} Floor</li>
    )
  }

  // Buy button click handler. call the pending. And call back hash set timer.
  // We also reload the main API call.
  function buyHandlerSet(clickedpropertyid) {
    
    setbuyBtnStatus(false);
    
    fetch(window.businessnamewindow+'pend/',
   // fetch(props.passapiurl()+'pend/',
    {
      method:'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({id: clickedpropertyid, user_hash:0})
    }
    )
    .then(response => {
      //console.log(response.status)
      if(response.ok){
        return response.json().then(data => {
          setthebookingModal(data); 
          setbuyBtnStatus(true);
          props.initrequestforward();
        })
      }else{
        return response.json().then(error => {
          console.log(error);
          setthebookingModal();
          setbuyBtnStatus(true);
          props.initrequestforward();
          setpassingMessage({'alertmessage':'Sorry this property is currently not available. Someone is busy booking. Please try again later.', 'alertstatus':3});
        })
      }

    }).catch(error => {throw new Error(error)})
  }// End

  function cancelHandlerSet() {

    setbuyBtnStatus(true) // Set btn false

    fetch(window.businessnamewindow+'pend/',
    {
      method:'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({id: thebookingModal.id, user_hash:thebookingModal.hash})
    })
    .then(response => {
      // If OK
      if(response.ok){
        return response.json().then(data => {
          setthebookingModal(''); 
          props.initrequestforward(); 
          setbuyBtnStatus(true);  
          setpassingMessage({'alertmessage':'Order has been closed and has been reopened.', 'alertstatus':1})
        })
      }else{
        setbuyBtnStatus(true); 
        setpassingMessage({'alertmessage':'There was an error canceling. This order will reopen after a set period.', 'alertstatus':3})
      }
    }).catch(error => {throw new Error(error)})
  }// End
 
  return (
    <>
    {imagepopViewState ? <ImageModal imagearray={imagepopViewState} imagePopHandlerClosePass={imagePopHandlerClose}  /> : ''}

    { passingMessage ? <MessageAlert message={passingMessage} /> : ''}
    { thebookingModal ? <BookingModal passingapiurl={props.passapiurl} selectedunit={thebookingModal} passingpasspropertyState2={props.passpropertyState2} cancelHandlerSetForward={cancelHandlerSet} forwardbookingmod={setthebookingModal} passingsetpassingMessage={setpassingMessage}  /> : ''}

    <div className={`${styles.inner_root_container}`} onClick={()=> setpassingMessage()}>

      <div className={`${styles.legend_container}`}>
        <div className={`${styles.legend_1}`}><span></span>Available</div>
        <div className={`${styles.legend_2}`}><span></span>Pending</div>
        <div className={styles.legend_3}><span></span>Sold</div>
      </div>

      <div className={`${styles.legend_container} ${styles.legend_floors}`}>
      { floorSetyArr.slice(0).reverse().map(item => <div onClick={() => {updateFloorState(item)}} className={ item === floorState ? styles.active : ''  }>{floorlabelingarray[item]} Floor</div> ) }
      </div>

      <MapMain data={allgroupproperties} forwardedRef={geoRef} passfloorstate={floorState} passtoolTipStylesHandler={toolTipStylesHandler} passsetimagepopstat={setimagepopViewState} />

      <div className={styles.sidebar}>

        <div className={styles.logo}>{ allgroupproperties.properties.logo ? <img  src={window.businessnamewindowroot.slice(0, -1)+allgroupproperties.properties.logo} /> : allgroupproperties.properties.group_name }</div>

        <div className={styles.floor_select}>
          <div className={`${styles.btn_base} ${styles.floor_select_btn}`} onClick={toggleFloors}>{floorlabelingarray[floorState]} Floor <img src="../../floors_icon.svg" /> </div>
          <ul className={toggle ? styles.active : ''}>
            { floorSetyArr.map(item => <Floors floornumber={item} /> ) }
          </ul>
        </div>

        <div className={styles.properties_con}>
          { allgroupproperties.properties.group_listings.features.filter(unit => unit.properties.property_floor_level === floorState).map(item => <SingleProperty forwardopenEnqView={()=>openEnqView(item.properties)} buyHandlerForward={buyHandlerSet} forwardBuyBtnStatus={buyBtnStatus} forwardedRef={geoRef} key={item.id} propid={item.id} title={item.properties.listing_title} price={item.properties.price_amount} status={item.properties.listing_status} bedrooms={item.properties.property_bedrooms} bathrooms={item.properties.property_bathrooms} floorsize={item.properties.property_floor_size} eftsize={item.properties.property_land_size}   />) }
        </div>

        

      </div>


      <div className={styles.powered_by}>Powered by <a href='https://designroom.co.za/?utm_source=leadarmap&utm_medium=map&utm_campaign=footer'>DesignRoom</a></div>

      <div id='sidebar_details_con' className={`${styles.sidebar} ${styles.sidebar_enquiry} ${EnquiryViewState?styles.active:''  }` } >

        <div className={`${styles.sidebar_topper_header_con}`}>  
          <div id='sidebar_details_close_btn' onClick={()=>closeEnqView(EnquiryViewProp.property_id)} className={styles.sidebar_topper_back}><img src="../../icon_back.svg" /></div>

          <button id='sidebar_details_close_btn_hidden' onClick={()=>closeEnqViewFromLayerReset(EnquiryViewProp.property_id)} hidden />

          <div>{ allgroupproperties.properties.group_name }</div>
        </div>

        { EnquiryViewProp?
        <div className={styles.full_details_con} >
        
            <PropDetailsSimple unit={EnquiryViewProp} status_live={true} />

             {/* { EnquiryViewProp.listing_type_media.length > 0 ? 
            <div className={styles.floor_plan_div} onClick={()=>setimagepopViewState(EnquiryViewProp.listing_type_media)} style={{backgroundImage: `url(${window.businessnamewindowroot.slice(0, -1)+EnquiryViewProp.listing_type_media[0].media_upload})`}}>
              <div className={styles.enlarge_icon}><img src="../../icon_enlarge.svg" /></div>
            </div> : ''}  */}

            {/* View Gallery */}
            { EnquiryViewProp.listing_type_media.filter(e=>e.is_enabled===true).filter(e=>e.media_type===1).length > 0 ? 
            <div onClick={()=>setimagepopViewState(EnquiryViewProp.listing_type_media.filter(e=>e.is_enabled===true).filter(e=>e.media_type===1))} className={`${styles.btn_base}`}>VIEW GALLERY ({EnquiryViewProp.listing_type_media.filter(e=>e.is_enabled===true).filter(e=>e.media_type===1).length})</div>: ''}

            {/* View Floor Plans */}
            { EnquiryViewProp.listing_type_media.filter(e=>e.is_enabled===true).filter(e=>e.media_type===4).length > 0 ? 
            <div onClick={()=>setimagepopViewState(EnquiryViewProp.listing_type_media.filter(e=>e.is_enabled===true).filter(e=>e.media_type===4))} className={`${styles.btn_base}`}>VIEW FLOOR PLANS ({EnquiryViewProp.listing_type_media.filter(e=>e.is_enabled===true).filter(e=>e.media_type===4).length})</div>: ''}

            <div>
              <div className={`${styles.cool_sub_heading} ${styles.get_intouch}`}>Request a call back</div>
              <div className={styles.cool_smallsub_heading}><b>Have a question?</b> Please fill the form below and one of our agents will contact you as soon as possible.</div>
              <FormEnquiry unit={EnquiryViewProp} passingsetpassingMessage={setpassingMessage} />
            </div>
        </div>
        : <div className={styles.cool_smallsub_heading}>No Property Selected.</div> }

      </div>
      
      
    </div>
    </>
    );
}
export default Container;