import React from 'react';
import styles from '../utilities/LoadingIcon.module.css' 

function LoadingIcon(props){

  function Sizing(){
    if(props.size){
      if(props.size === 1){
        return(<div className={`${styles.loadingicon} ${styles.loadingicon_1} `}></div>)
      }if (props.size === 2) {
        return(<div className={`${styles.loadingicon} ${styles.loadingicon_2} `}></div>)
      } else {
        return(<div className={`${styles.loadingicon} ${styles.loadingicon_3} `}></div>)
      }
    }else{
      return(<div className={`${styles.loadingicon}`}></div>)
    }
  }

  return(
    <>
    <Sizing/>
    </>
  )
}
export default LoadingIcon;