import React, { useEffect, useState } from 'react';
import Container from '../components/map/Container';
import LoadingModule from '../components/utilities/LoadingModule';
import {useParams} from 'react-router-dom';
//import styles from '../components/map/Map.module.css';
//import '../components/map/Map.css';

function PageMap(props) {

  const [propertyState2, setPropertyState2] = useState()
  const [loadingState, setloadingState] = useState(true)
  const [loadingErrorText, setloadingErrorText] = useState('Connection Lost.')
  const { businessname, mapid } = useParams();

  window.businessnamewindowroot = `https://`+businessname+window.mainURL;
  window.businessnamewindow = `https://`+businessname+window.mainURL+`api/v1/property/`;
  window.wsbusinessnamewindow = `wss://`+businessname+window.mainURL+`ws/v1/`;

  const initrequest = async () => {

    async function fetchMapStatus() {
      //setloadingState(true);
      //Fetch
      await fetch(window.businessnamewindow + `map/`+mapid,
      {
        method:'GET',
      })
      .then(async response => {
        if(response.ok){
          const data = await response.json();

          // Check if map enabled.
          if(data.features[0].properties.sitemap_is_enabled){
            setloadingState(false)
            //return json;
            setPropertyState2(data)
            //console.log(data)
            //await fetchMapStatus();
          }else{
            setloadingState(false)   
          }
        }else{
          const error = await response.json();
          setloadingState(false)
          //setthemessage({'alertmessage':error, 'alertstatus':1})
        }
      })
      .catch(() => {
          setloadingState(false);
          //setthemessage({'alertmessage':'Server Error. Please try again.', 'alertstatus':1})
      });
      //.Fetch      
    }
    // Catch error.
    //fetchMapStatus().catch(setloadingState(false))
    fetchMapStatus()
  }
  // useEffect.
  useEffect(()=>{
   //initrequest(); //DISBALED AND NOW USING WS://
  },[]); 


  // Connect to WS 
  function wsconnect(){
    setloadingErrorText('Connection Lost.')
    
    const ws = new WebSocket(window.wsbusinessnamewindow + `map/`+mapid+`/`);

    ws.onopen = function(e) {
      console.log('Connection open...')
    }

    ws.onmessage = function(e) {
      const json = JSON.parse(e.data);
      
    // Check if map enabled.
    if(json.features[0].properties.sitemap_is_enabled){
      setloadingState(false)
      setPropertyState2(json)
    }else{
      setloadingState(false)
      setPropertyState2()
      setloadingErrorText('Map is offline. Please try again later.')
    }
      console.log('Message from server...')
    }

    ws.onclose = function(e) {
      console.error(e)
      ws.close();
      setPropertyState2()
      setloadingState(false);
      console.error('Connection was closed...');
    }

    ws.onerror = function(e) {
      ws.close();
      setPropertyState2()
      setloadingState(false)
      console.error('Server error!');
      
    }
  }

  // useEffect.
  useEffect(()=>{
    wsconnect();
  },[]); 


  // Return
  return ( 
    <>
    {loadingState ? <LoadingModule loadtype={1} loadmessage='Loading...' /> : propertyState2 ? <><Container passpropertyState2={propertyState2} initrequestforward={initrequest} /></> : <LoadingModule loadtype='' loadmessage={loadingErrorText} refreshcall={wsconnect} /> }
    </>
    );
}

export default PageMap;