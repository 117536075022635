import React from 'react';
import NumberFormat from '../utilities/NumberFormat';
import styles from './Map.module.css';

function SinglePrice(props) {
  return (
    <div className={styles.price}>{props.cur}<NumberFormat numtoformat={props.price} /></div>
  );
}

export default SinglePrice;