import React from 'react';
import styles from '../utilities/LoadingIcon.module.css' 
import LoadingIcon from './LoadingIcon';

function LoadingModule(props){

  function Sizing(){
    if(props.loadtype === 1){
      return(<div className={`${styles.openloading}`}><LoadingIcon size={3} /><div>{props.loadmessage}</div></div>)
    } else {
      return(<div className={`${styles.openloading} ${styles.error}`}><div className={`${styles.error_icon}`}>!</div><div>{props.loadmessage}</div>{props.refreshcall ? <div onClick={props.refreshcall} className={`${styles.refresh_btn}`}> <img src='../../icon_refresh.svg' /> <div>Click here to reconnect</div></div>:''}</div>)
    }
  }

  return(
    <>
    <Sizing/>
    </>
  )
}
export default LoadingModule;
