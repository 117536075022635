import {MapContainer, TileLayer, GeoJSON,ImageOverlay, ZoomControl, Marker,Popup, Tooltip } from 'react-leaflet';
import React, {useState,useCallback,useRef} from 'react';
import 'leaflet/dist/leaflet.css'
import styles from './Map.module.css';
import L from 'leaflet';
import PropDetailsSimple from './PropDetailsSimple';

function MapMain(props){
  const prop_mapcenter = props.data.geometry.coordinates
  const imageoverlayurl = window.businessnamewindowroot.slice(0, -1)+props.data.properties.sitemap_image_overaly
  // Filter this per floor
  const prop_plotting = props.data.properties.group_listings.features.filter(unit => unit.properties.property_floor_level === props.passfloorstate)
  console.log(props.data)
  // Image overaly plotting
  const imageoverlayplotting = props.data.properties.group_geo_imageplotting.coordinates
  // Image overaly plotting - Array so we can invert the points for Leaflet
  const imageoverlayplotting_array = []
  imageoverlayplotting[0].map( (item, index) => { imageoverlayplotting_array.push(  [item[1], item[0]]  ) })
  // Set Map state. When loading. If needed.
  const [map, setMap] = useState(null);
  // Retive GeoJsonRef from forward
  const geoJsonRef = props.forwardedRef
  // GeoJSON styling. We apply an class number to the a class so we can reach if need be. We reach when clicked on single sidbar.

  // function loadMarkers(){
  //   //Add Markers
  //   //if(map){
  //   var photoMarker = L.divIcon({html:`<div><img src="../../icon_cam.svg" /></div>`, className:styles.marker_photo, iconSize:[40,40], iconAnchor:[20,20]});
  //   L.marker([-26.10244, 28.03121],{icon:photoMarker,title:'Enterance to Bailey'}).on('click',  ()=>props.passsetimagepopstat({'url':'../../temp_plan.png', 'title':'Floro plans', 'desc':'These are floor plans'})).addTo(map);
  // // }
  //   //.## Selected function 
  // }
  const photoMarkerIcon = L.divIcon({html:`<div><img src="../../icon_map_photo.svg" /></div>`, className:styles.marker_photo, iconSize:[50,50], iconAnchor:[25,25]});

  const locationMarkerIcon = L.divIcon({html:`<div><img src="../../icon_map_loc_med.svg" /></div>`, className:styles.marker_location, iconSize:[40,40], iconAnchor:[20,20]});

  // Tooltip styling. Based on status etc.
  function letstyle(e){
    return {
      fillColor: '',
      fillOpacity: '0.8',
      color: '#000000', // Stroke fill
      weight: '1', // Stroke weight
      //className: `${styles.plot_class} ${styles[`plot_class_`+setUpdatedObject.properties.listing_status]}`+` plot_`+setUpdatedObject.id
      className: `${styles.plot_class} ${styles[`plot_class_`+e.properties.listing_status]}`+` plot_`+e.id
    }
  }


  // On load of each geoJson element. apply these binds. e.g. popup etc.
  function onEachUnit(e, layer){

    //Add tootip numbering
    layer.bindTooltip(`<div>`+e.properties.property_number+`</div>`, {permanent:true, direction:'center', className:`${styles.plot_tooltip}`})


    

    // function openFullDetailsFromLayer(id){
    //   document.getElementById("enquire_btn_id_"+id).click()
    // }

    const popupHTML = `
    <div class=${styles.plot_class}>
      <div class="${styles.pop_header}"><div class="${styles.pop_header_text}">`+e.properties.listing_title+`</div></div>
      <div class="${styles.pop_price}">`+e.properties.price_amount.toLocaleString(undefined, { maximumFractionDigits: 2 })+`</div>
      <div class="${styles.btn_base} ${styles.btn_2}" onClick="()=>console.log('123')">DETAILS / ENQUIRE</div>
    </div>`

    //Bind popup. You could create a full popup and add it to the layer L.popup ect. But this is a shortcut.
    layer.bindPopup(popupHTML,{offset:[0, -10],pane:'popupPane'});
    //layer.bindPopup(`<div class=${styles.plot_class}><div className={`${styles.btn_base} ${styles.btn_2}`}>DETAILS / ENQUIRE</div></div>`,{offset:[0, -10],pane:'popupPane'});



    


    layer.on('click', function (el) { 

      // Hide the sidebar full details of the layer plot if clicked directly.
      // First we check if it was a human click or a program click. originalEvent checks this. Then if the class active is there. Then we can close.
      // Create a second hidden btn sidebar_details_close_btn_hidden for just this. So it does not retrigger map click.
      if(el.originalEvent != undefined && document.getElementById('sidebar_details_con').classList.contains(styles.active)) {
        document.getElementById('sidebar_details_close_btn_hidden').click()
      }
      
      // Center layer popup.
      layer._popup.setLatLng([layer._tooltip._latlng.lat, layer._tooltip._latlng.lng]);

      //Clear highlighted sidebar elements
      const class_name=styles.highlight;
      const elements=document.getElementsByClassName(class_name)
      for(const element of elements){
        element.classList.remove(class_name)
      }
      //.End

      // Sidebar mini single prop
      const single_prop = document.getElementById("single_prop_id_"+e.id)
      // Highlight side bar
      single_prop.classList.add(styles.highlight);
      // Scroll to top. Better than anchor.
      single_prop.scrollIntoView({behavior: "smooth"});

      // Sidebar full details
      // if(document.getElementById(styles.sidebar_details_con)){
      //   const sidebar_single_det_con = document.getElementById(styles.sidebar_details_con)
      //   sidebar_single_det_con.classList.remove(styles.active)
      //   open_side_details();
      // }

      //open_side_details();

      // function open_side_details(){
      //   const single_prop_enquire_slide = document.getElementById("enquire_btn_id_"+e.id)
      //   single_prop_enquire_slide.click()
      // }

      //## Selected function 
      // We add a maker on top of the tooltip for the selcted marker.
      // Look up all markers. Then find marker with title selected. And remove any. So we can add new selected.
      const findSelectedMarkers = layer._map._layers
      for (const e in findSelectedMarkers) {
        const liveselected = layer._map._layers[e].options.title 
        if(liveselected == 'SELECTED' || layer._map <= props.data.properties.map_set_zoom){
          layer._map._layers[e].remove() //Remove any selected markers. Only one at a time.
        }      

      }


      var selectedIcon = L.divIcon({iconSize:[40,40], iconAnchor:[20,20], className:styles.plot_selected_marker_little, html:''});
      L.marker([layer._tooltip._latlng.lat, layer._tooltip._latlng.lng],{icon:selectedIcon,title:'SELECTED'}).addTo(layer._map);

    });

    // Hover over effect on plotting unit.
    layer.on('mouseout', function (el) { 
     // document.getElementById("single_prop_id_"+e.id).classList.remove(styles.highlight);
    });

    // Hover over effect on plotting unit.
    layer.on('mouseover', function (el) { 
     // document.getElementById("single_prop_id_"+e.id).classList.remove(styles.highlight);
    });
  }

  function updateZoomedIcons(){
    var zoom = map.getZoom();

    if (zoom >= props.data.properties.map_set_zoom) {
      map.eachLayer(function(l) {
        console.log('tooltip open')

        // loop layers and look for marker that selected. Then we can hide with css. 
        if(l.options.markergroup == 'gallery' ){
          l._icon.style.display = 'flex';
        }

        // loop layers and look for marker that selected. Then we can /show with css. 
        if(l.options.title == 'SELECTED' ){
          l._icon.style.display = 'flex';
        }
        // loop layers and look for tool tips. So we can hide on zoom.
        if (l.getTooltip) {
          var toolTip = l.getTooltip();
          if (toolTip) {
            map.addLayer(toolTip);
          }
        }
      });
    // } else if (zoom < props.data.properties.map_set_zoom) {
    } else{
      map.eachLayer(function(l) {

        console.log(l)

        // loop layers and look for marker that selected. Then we can hide with css. 
        if(l.options.markergroup == 'gallery' ){
          l._icon.style.display = 'none';
        }

        // loop layers and look for marker that selected. Then we can hide with css. 
        if(l.options.title == 'SELECTED' ){
          l._icon.style.display = 'none';
        }

        // loop layers and look for tool tips. So we can hide on zoom.
        if (l.getTooltip) {
          console.log('tooltip close')
          var toolTip = l.getTooltip();
          if (toolTip) {
            map.closeTooltip(toolTip);
          }
        }
      });
    }

    // Location Markers hide/show
    if (zoom >= 15) {
      map.eachLayer(function(l) {
    
        // loop layers and look for marker that selected. Then we can hide with css. 
        if(l.options.markergroup == 'location' ){
          l._icon.style.display = 'flex';
        }

      });
    // } else if (zoom < props.data.properties.map_set_zoom) {
    } else{
      map.eachLayer(function(l) {

        // loop layers and look for marker that selected. Then we can hide with css. 
        if(l.options.markergroup == 'location' ){
          l._icon.style.display = 'none';
        }

      });
    }
  };
  
  // Hide show tooltips based on zoom
  if(map){
    map.on('zoomend', function() {
      updateZoomedIcons();
    });
  }
  // retain tooltip hide/show if user is zoomed out we have a WC render when someone else books. Timer is due small hack.
  if(map){
      setTimeout(function() {
        //your code to be executed after 1 second
        updateZoomedIcons();
      }, 1);
  }
  
  // Made this a function so if we update stats it re-renders. And updates the styles etc.
  // ref is set in the main app. Its forwarded back. So the sidebar can reach too.
  function GeoHandler(){
    return (<GeoJSON data={prop_plotting} style={letstyle}  onEachFeature={onEachUnit} ref={props.forwardedRef}  />);
  }

  return(
    <>

    <MapContainer  center={[prop_mapcenter[1],prop_mapcenter[0]]} zoom={props.data.properties.map_set_zoom} maxZoom={props.data.properties.map_max_zoom} zoomControl={false} touchZoom={true} trackResize={true} wheelPxPerZoomLevel={50} id={`${styles.map}`} whenCreated={setMap}>
      <ZoomControl position="bottomright" />
      <TileLayer
      //ref={mapRef}
      //attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      attribution='&copy; <a href="https://designroom.co.za/" target="_blank">Sales Map by Design Room</a>'
      //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      url='https://api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=WZzzhDr1ycbA3OEGOJATh1cA5f9xJUtJ&tileSize=512'
      maxNativeZoom={19}
      maxZoom={40}
      />

      <GeoHandler />  
      <ImageOverlay
      url={imageoverlayurl}
      bounds={imageoverlayplotting_array}
      opacity={1}
      zIndex={10}
      />

      { props.data.properties.gallery.filter(item=>item.is_image_enabled===true).filter(item=>item.is_pin_enabled===true).map(item => 
        
        <Marker 
        position={[item.pin_latlng.split(",")[0], item.pin_latlng.split(",")[1]]} 
        icon={photoMarkerIcon} 
        title={item.media_name}
        markergroup={'gallery'}
        eventHandlers={{
          click: () => {
            props.passsetimagepopstat([item])
          },
        }}/> 

       )}


      { props.data.properties.points.filter(item=>item.is_enabled===true).filter(item=>item.pin_latlng!=null).map(item => 
        
        <Marker 
        position={[item.pin_latlng.split(",")[0], item.pin_latlng.split(",")[1]]} 
        icon={locationMarkerIcon} 
        title={item.point_name}
        markergroup={'location'}>

        <Popup direction="center" offset={[0, -15]} opacity={1} permanent>{item.point_name}</Popup>
        {/* <Tooltip direction="center" offset={[0, 40]} opacity={1} permanent>{item.point_name}</Tooltip> */}
        </Marker>
      
       )}


    </MapContainer>
    </>
  )
}
export default MapMain;