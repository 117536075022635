import React, { useState } from 'react';
import LoadingIcon from '../utilities/LoadingIcon';
import styles from './Map.module.css';
import 'leaflet/dist/leaflet.css';
import ReCAPTCHA from "react-google-recaptcha";

function FormEnquiry(props){

  const [values, setValues] = useState({
    first_name:'',
    last_name:'',
    email:'',
    mobile:'',
  });

  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({...oldValues, [name]: value }));
    }
  };

  
  //Recapture call back token
  const [recapturetoken, setrecapturetoken] = useState()
  function onRecapChange(recap_token) {
    setrecapturetoken(recap_token)
  }

  const [apiErrors, setapiErrors] = useState()
  const [submitLoading, setsubmitLoading] = useState()

  function submitHandler(e) {

      setsubmitLoading(true) //Set loading..

      e.preventDefault();

      fetch(window.businessnamewindowroot + 'api/v1/nl/',
      {
        method:'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          first_name:values.first_name,
          last_name:values.last_name,
          email:values.email,
          mobile:values.mobile,
          tel:'',
          enquiry_about:props.unit.listing_title,
          enquiry_comments:'',
          enquiry_campaign_ref:'',
          enquiry_source:'leadar_map',
          enquiry_medium:'form',
          enquiry_name:'',
          enquiry_term:'',
          enquiry_content:"",
          page_history:'',
          marketing_agree:true,
          agree_terms_client:true,
          recaptcharesponse:recapturetoken
        })
      }
      ).then(response => {
        if(response.ok){
          return response.json().then(data => {
            props.passingsetpassingMessage({'alertmessage':'Thank you! Your enquiry has been sent. We will contact you shortly.', 'alertstatus':1});
            setapiErrors();
            setsubmitLoading(false) //Set loading..

            setValues({first_name:'',last_name:'',email:'',mobile:'',})
            setrecapturetoken()
            window.grecaptcha.reset();
          })
        }else{
          setsubmitLoading(false) //Set loading..
          setrecapturetoken()
          window.grecaptcha.reset();
          return response.json().then(error => {setapiErrors(error); console.log(error);})
        }

        
      })
      .catch((error) => {
        // Network errors only!
        setapiErrors(error)
        console.log(error)
        setrecapturetoken()
        setsubmitLoading(false) //Set loading..
        window.grecaptcha.reset();
      });

      //setsubmitLoading(false) //Set loading..
    }
  

    return (

      <>

      {apiErrors ? <div className={styles.error_large}>{apiErrors.server_error}</div> : ''} 

      <form>
        <div>
          <label for='first_name'>First Name {apiErrors ? <span className={styles.error_label}>{apiErrors.first_name}</span> : ''}</label>
          <input type="text" value={values.first_name} onChange={set('first_name')} />
        </div>

        <div>
          <label for='last_name'>Last Name {apiErrors ? <span className={styles.error_label}>{apiErrors.last_name}</span> : ''}</label>
          <input type='text' value={values.last_name} onChange={set('last_name')}/>
        </div>

        <div>
          <label for='email'>Email Address {apiErrors ? <span className={styles.error_label}>{apiErrors.email}</span> : ''}</label>
          <input type='text' id='email' value={values.email} onChange={set('email')}  />
        </div>

        <div>
          <label for='mobile'>Mobile Number {apiErrors ? <span className={styles.error_label}>{apiErrors.mobile}</span> : ''}</label>
          <input type='text' id='mobile' value={values.mobile} onChange={set('mobile')} />
        </div>

        <div className={styles.recatchcon}>
          <ReCAPTCHA
            sitekey="6Lc3OB0dAAAAAFvfolNacUOPamh6IwZUTLnuwlXN" 
            onChange={onRecapChange}
          />
        </div>

      </form>

      {submitLoading ? <div className={`${styles.btn_base} ${styles.btn_2} ${styles.btn_loading}`}><div>Please Wait</div> <LoadingIcon size={1} /></div> : <div className={`${styles.btn_base} ${styles.btn_2}`} onClick={submitHandler}>PLEASE CONTACT ME <img src='../../icon_phone_white.svg' /> </div> }

      {/* : <div className={`${styles.btn_base} ${styles.btn_2}`} onClick={submitHandler}>PLEASE CONTACT ME <img src='../../icon_phone_white.svg' /> </div>  */}

      </>
  );
}


export default FormEnquiry;