import React, {useState,useEffect, useCallback} from 'react';
import styles from './Map.module.css';

function CountDownTimer(props){

  //console.log(props.allocatedtime)

  const allowed_time_mins = props.allocatedtime

  // New count down timer does not delay on time date. Its now. 
  const [userTime, setuserTime] = useState(new Date().setMinutes(new Date().getMinutes() + allowed_time_mins)); // Force close based on users timestamp. Backup in case user closed laptop. 
  const [simpleCountdownmin, setsimpleCountdownmin] = useState(allowed_time_mins - 1); // Take off 1min start at 59 sec
  const [simpleCountdownsec, setsimpleCountdownsec] = useState(59);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Trigger timer every sec. Count down. No date.
      simpleCounter();

    }, 1000);
    return () => clearInterval(intervalId);
    
  }, [simpleCounter]);

  //console.log(simpleCountdownsec)
    // Count down timer function
    function simpleCounter(){

      // Force close based on users timestamp
      if(new Date() > userTime){
        props.forwardformvis(false)
        // Cancel booking. Also apply server side as backup.
        props.forwardcancelHandlerSetForward()
      }

      if(simpleCountdownsec > 0){
        setsimpleCountdownsec(simpleCountdownsec => simpleCountdownsec - 1);
      }
  
      if(simpleCountdownsec === 0){
  
        if(simpleCountdownmin === 0){
          // clearInterval(interval)
          //setbookVis(false)
          props.forwardformvis(false)
          // Cancel booking. Also apply server side as backup.
          props.forwardcancelHandlerSetForward()
  
        }else{
          setsimpleCountdownmin(simpleCountdownmin - 1)
          setsimpleCountdownsec(59);
        }
      }
      //console.log(simpleCountdownmin+`:`+simpleCountdownsec);
    }

    return (
    <div className={`${styles.timer_text}`}>TIME LEFT {('0' + simpleCountdownmin).slice(-2)+`:`+('0' + simpleCountdownsec).slice(-2)}</div>
  );
}

export default CountDownTimer;