import React, { useHistory, useRef, useState } from 'react';
import LoadingIcon from '../utilities/LoadingIcon';
import styles from './Map.module.css';

function FormBuyer(props){

  const [values, setValues] = useState({
    first_name:'',
    last_name:'',
    email:'',
    con_email:'',
    mobile:'',
    passport:'',
    address_1:'',
    address_2:'',
    town_city:'',
    province:'',
    town_city:'',
    p_code:'',
  });

  //const [errors, setErrors] = useState([])

  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({...oldValues, [name]: value }));
    }
  };

  const [apiErrors, setapiErrors] = useState()

  const [submitLoading, setsubmitLoading] = useState()

  function submitHandler(e) {

      setsubmitLoading(true) //Set loading..

      e.preventDefault();

      fetch(window.businessnamewindow + 'pend-user/',
      {
        method:'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          id: props.passunit.id,
          user_hash: props.passunit.hash,
          first_name:values.first_name,
          last_name:values.last_name,
          email:values.email,
          con_email:values.con_email,
          mobile:values.mobile,
          passport:values.passport,
          country:values.country,
          address_1:values.address_1,
          address_2:values.address_2,
          town_city:values.town_city,
          province:values.province,
          town_city:values.town_city,
          p_code:values.p_code,
          marketing_agree:true,
        })
      }
      ).then(response => {
        if(response.ok){
          return response.json().then(data => {
            props.passingsetpassingMessage({'alertmessage':'Your pre-booing was successful. Please check your email for futher instructions', 'alertstatus':1});
            props.forwardsuccess('');
            setapiErrors();
            setsubmitLoading(false) //Set loading..
          })
        }else{
          setsubmitLoading(false) //Set loading..
          return response.json().then(error => {setapiErrors(error); console.log(error);})
        }

        
      })
      .catch((error) => {
        // Network errors only!
        setapiErrors(error)
        console.log(error)
        setsubmitLoading(false) //Set loading..
      });

      //setsubmitLoading(false) //Set loading..
    }
  

    return (

      <>

      {apiErrors ? <div className={styles.error_large}>{apiErrors.server_error}</div> : ''} 

      <form>
        <div>
          <label for='first_name'>First Name {apiErrors ? <span className={styles.error_label}>{apiErrors.first_name}</span> : ''}</label>
          <input type="text" value={values.first_name} onChange={set('first_name')} />
        </div>

        <div>
          <label for='last_name'>Last Name {apiErrors ? <span className={styles.error_label}>{apiErrors.last_name}</span> : ''}</label>
          <input type='text' value={values.last_name} onChange={set('last_name')}/>
        </div>

        <div>
          <label for='email'>Email Address {apiErrors ? <span className={styles.error_label}>{apiErrors.email}</span> : ''}</label>
          <input type='text' id='email' value={values.email} onChange={set('email')}  />
        </div>

        <div>
          <label for='con_email'>Confirm Email Address {apiErrors ? <span className={styles.error_label}>{apiErrors.con_email}</span> : ''}</label>
          <input type='text' id='con_email' value={values.con_email} onChange={set('con_email')}  />
        </div>

        <div>
          <label for='mobile'>Mobile Number {apiErrors ? <span className={styles.error_label}>{apiErrors.mobile}</span> : ''}</label>
          <input type='text' id='mobile' value={values.mobile} onChange={set('mobile')} />
        </div>

        <div>
          <label for='passport'>ID/Passport Number {apiErrors ? <span className={styles.error_label}>{apiErrors.passport}</span> : ''}</label>
          <input type='text' id='passport' value={values.passport} onChange={set('passport')} />
        </div>

        <div>
          <label for='country'>Country {apiErrors ? <span className={styles.error_label}>{apiErrors.country}</span> : ''}</label>
          <input type='text' id='country' value={values.country} onChange={set('country')} />
        </div>

        <div>
          <label for='address_1'>Address Line 1 {apiErrors ? <span className={styles.error_label}>{apiErrors.address_1}</span> : ''}</label>
          <input type='text' id='address_1' value={values.address_1} onChange={set('address_1')} />
        </div>

        <div>
          <label for='address_2'>Address Line 2 {apiErrors ? <span className={styles.error_label}>{apiErrors.address_2}</span> : ''}</label>
          <input type='text' id='address_2' value={values.address_2} onChange={set('address_2')} />
        </div>

        <div>
          <label for='town_city'>Town/City {apiErrors ? <span className={styles.error_label}>{apiErrors.town_city}</span> : ''}</label>
          <input type='text' id='town_city' value={values.town_city} onChange={set('town_city')} />
        </div>

        <div>
          <label for='province'>Province {apiErrors ? <span className={styles.error_label}>{apiErrors.province}</span> : ''}</label>
          <input type='text' id='province' value={values.province} onChange={set('province')} />
        </div>

        <div>
          <label for='p_code'>Post Code {apiErrors ? <span className={styles.error_label}>{apiErrors.p_code}</span> : ''}</label>
          <input type='text' id='p_code' value={values.p_code} onChange={set('p_code')} />
        </div>
      </form>

      <div title='Click here to cancel your pre-booking.' className={`${styles.btn_base} ${styles.btn_2} ${styles.btn_close}`} onClick={() => props.forwardcancelHandlerSetForward()}>CANCEL BOOKING <img src='../../icon_cross_grey.svg' className={styles.icon} /></div>

      {submitLoading ? <div className={`${styles.btn_base} ${styles.btn_2} ${styles.btn_loading}`}><div>Please Wait</div> <LoadingIcon size='1' /></div> : <div className={`${styles.btn_base} ${styles.btn_2}`} onClick={submitHandler}>SUBMIT DETAILS <img src='../..//icon_tick_white.svg' /></div> }

      </>
  );
}


export default FormBuyer;