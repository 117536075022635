import React, {useEffect, useState} from 'react';
import L from 'leaflet';
import SinglePropHeading from './SinglePropHeading';
import SinglePropRow from './SinglePropRow';
import SinglePropBtns from './SinglePropBtns';
import SinglePrice from './SinglePrice';
import styles from './Map.module.css';
import './leaflet.css'
import reactDom from 'react-dom';


function SingleProperty(props){

  // Retrive geoJson from main app ref. So its reachable to other apps.
  const geoJsonRef = props.forwardedRef

  const singlePropHandler = (e) =>{

    // Find map plotted element e.g. Unit. 
    // Retive geoJson ref from main app. Loop over and find matching property ID's. Then apply fitbound to pan to the correct plot.
    const findMapPlot = geoJsonRef.current.getLayers().find((singlelayer) =>  singlelayer.feature.id === props.propid)

    findMapPlot.openPopup();
    findMapPlot.fireEvent('click');  // Emulate a click. All business logic on the on.layer event MapMain.js
    // Fit Bounds with padding. Pad 1
    findMapPlot._map.fitBounds(findMapPlot.getBounds().pad(1));
  }
  
  return(
    <div className={styles.single_property} id={`single_prop_id_`+props.propid} onClick={()=>{singlePropHandler()}}>
    <SinglePropHeading title={props.title} status={props.status}  />

    <div className={styles.details_rows_con} >
      <SinglePropRow name='Bedrooms' number={props.bedrooms} />
      <SinglePropRow name='Bathrooms' number={props.bathrooms} />
      <SinglePropRow name='Size(sqm)' number={props.floorsize} />
      <SinglePropRow name='Eft(sqm)' number={props.eftsize} />
    </div>

    <SinglePrice price={props.price}  cur='R' />

    <div className={`${styles.btn_base} ${styles.btn_2}`} id={`enquire_btn_id_`+props.propid} onClick={()=>{props.forwardopenEnqView(); setTimeout(function() {singlePropHandler()}, 1000); }}>DETAILS / ENQUIRE</div>

    {/* <SinglePropBtns forwardBuyBtnStatus={props.forwardBuyBtnStatus} status={props.status} propertyid={props.propid} buyHandler={props.buyHandlerForward} /> */}

    </div>
  )

}
export default SingleProperty;