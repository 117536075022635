import SinglePropHeading from './SinglePropHeading';
import SinglePropRow from './SinglePropRow';
import SinglePrice from './SinglePrice';
import styles from './Map.module.css';

function PropDetailsSimple(props) {
  return (
    <div className={`${styles.single_property} ${styles.single_property_no_hover}`}>
    <SinglePropHeading title={props.unit.listing_title} status={props.unit.listing_status} />
    <div className={styles.details_rows_con}>
      <SinglePropRow name='Bedrooms' number={props.unit.property_bedrooms} />
      <SinglePropRow name='Bathrooms' number={props.unit.property_bathrooms} />
      <SinglePropRow name='Size(sqm)' number={props.unit.floor_size} />
      <SinglePropRow name='Eft(sqm)' number={props.unit.land_size} />
    </div>
    <SinglePrice price={props.unit.price_amount}  cur='R' />
  </div>
  );


  }

export default PropDetailsSimple;